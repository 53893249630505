import React, { useCallback, useState } from 'react';
import BasicLayout from 'src/components/layouts/BasicLayout';
import { H1, H4, Paragraph } from 'src/DesignSystem';
import d12Diagram from 'src/images/d12.svg';

interface SideEntryProps {
  name: string;
  value: number;
  setValue: (newValue: number) => void;
  error?: boolean;
  disabled?: boolean;
}

function SideEntry(props: SideEntryProps): JSX.Element {
  const displayValue = (props.value === undefined || isNaN(props.value)) ? '' : props.value;
  return (
    <div className="flex items-baseline">
      <span className="mr-4 flex-grow text-right">{props.name}</span>
      <input
        type="number"
        value={displayValue}
        disabled={props.disabled}
        onChange={event => props.setValue(parseInt(event.target.value))}
        className="border-2 border-gray-300 rounded py-1 px-2 flex-grow"
      />
    </div>
  );
}

interface DieCalculationResult {
  absoluteError: number;
  legal: boolean;
  optimal: boolean;
  worstCase: boolean;
}

const calcSidesError = (
  sides: number[],
  side1: number,
  side2: number,
  side3: number,
  side4: number,
  side5: number,
  side6: number
): number =>
  Math.abs(
    sides[side1] + sides[side2] + sides[side3] + sides[side4] + sides[side5] + sides[side6] - 39
  );

const calculateResults = (die: number[]): DieCalculationResult => {
  const errors: number[] = [];
  errors.push(calcSidesError(die, 0, 1, 2, 3, 4, 5));
  errors.push(calcSidesError(die, 0, 1, 2, 3, 10, 11));
  errors.push(calcSidesError(die, 0, 1, 4, 5, 8, 9));
  errors.push(calcSidesError(die, 0, 1, 2, 5, 9, 10));
  errors.push(calcSidesError(die, 0, 3, 4, 5, 7, 8));
  errors.push(calcSidesError(die, 0, 2, 3, 4, 7, 11));
  const totalError = errors.reduce((a, b) => a + b) * 2;

  const isLegal =
    die[0] + die[6] === 13 &&
    die[1] + die[7] === 13 &&
    die[2] + die[8] === 13 &&
    die[3] + die[9] === 13 &&
    die[4] + die[10] === 13 &&
    die[5] + die[11] === 13;

  return {
    absoluteError: totalError,
    legal: isLegal,
    optimal: totalError === 44,
    worstCase: totalError === 116,
  };
};

export default function D12Test(): JSX.Element {
  const [faces, setFaces] = useState<number[]>([12]);
  const [results, setResults] = useState<DieCalculationResult>();

  const updateFace = useCallback(
    (position: number, newValue: number) => {
      const newFaces = [...faces];
      newFaces[position] = newValue;
      setFaces(newFaces);
    },
    [faces]
  );

  return (
    <BasicLayout>
      <div className="max-w-4xl p-4 mx-auto text-gray-900 mb-8">
        <H1>D12 Tester</H1>
        <Paragraph>
          Not all d12s are created equal. To see just how balanced, or unbalanced, the side layout
          of your d12 is, fill out the tool below.
        </Paragraph>
        <div className="flex">
          <img src={d12Diagram} className="mr-8" />
          <div>
            <H4>How to Test Your Die</H4>
            <ol className="list-decimal">
              <li>Orient your d12 so that 12 face is facing you, as indicated in the diagram.</li>
              <li>Fill in faces A-E, as indicated by the diagram.</li>
              <li>Fill in the opposite of each of the faces.</li>
              <li>See how optimal your d12 layout is!</li>
            </ol>
          </div>
        </div>

        <div className="grid grid-rows-6 grid-flow-col gap-4 justify-items-stretch mt-8">
          <SideEntry name="Side 12" value={faces[0]} disabled setValue={() => {}} />
          <SideEntry
            name="Side A"
            value={faces[1]}
            setValue={(newValue: number): void => updateFace(1, newValue)}
          />
          <SideEntry
            name="Side B"
            value={faces[2]}
            setValue={(newValue: number): void => updateFace(2, newValue)}
          />
          <SideEntry
            name="Side C"
            value={faces[3]}
            setValue={(newValue: number): void => updateFace(3, newValue)}
          />
          <SideEntry
            name="Side D"
            value={faces[4]}
            setValue={(newValue: number): void => updateFace(4, newValue)}
          />
          <SideEntry
            name="Side E"
            value={faces[5]}
            setValue={(newValue: number): void => updateFace(5, newValue)}
          />
          <SideEntry
            name="Opposite Side 12"
            value={faces[6]}
            setValue={(newValue: number): void => updateFace(6, newValue)}
          />
          <SideEntry
            name="Opposite Side A"
            value={faces[7]}
            setValue={(newValue: number): void => updateFace(7, newValue)}
          />
          <SideEntry
            name="Opposite Side B"
            value={faces[8]}
            setValue={(newValue: number): void => updateFace(8, newValue)}
          />
          <SideEntry
            name="Opposite Side C"
            value={faces[9]}
            setValue={(newValue: number): void => updateFace(9, newValue)}
          />
          <SideEntry
            name="Opposite Side D"
            value={faces[10]}
            setValue={(newValue: number): void => updateFace(10, newValue)}
          />
          <SideEntry
            name="Opposite Side E"
            value={faces[11]}
            setValue={(newValue: number): void => updateFace(11, newValue)}
          />
        </div>

        <div className="flex justify-end mt-4">
          <button
            className="bg-blue-200 hover:bg-blue-300 px-2 py-1 rounded text-right"
            onClick={(): void => setResults(calculateResults(faces))}
          >
            Calculate
          </button>
        </div>

        {results && (
          <div>
            <H4>{results.legal ? 'This die is legal' : 'This die is not legal'}</H4>
            <Paragraph>
              A d12 is considered &quot;legal&quot; when every pair of opposite sides add up to 13.
            </Paragraph>
            {results.optimal && (
              <>
                <H4>This die is optimal!</H4>
                <Paragraph>
                  The configuration of faces on this die is optimal, balancing all bisections as
                  evenly as possible.
                </Paragraph>
              </>
            )}
            {results.worstCase && (
              <>
                <H4>This die is horrible!</H4>
                <Paragraph>
                  The configuration of faces on this die is a worst-case scenario, where poor weight
                  distribution may result in higher or lower numbers being favored.
                </Paragraph>
              </>
            )}
            <H4>Total Error: {results.absoluteError}</H4>
            <Paragraph>
              This value represents how well the faces of your die are laid out. A value of 44 is
              optimal, and 116 is the worst possible way to lay out faces.
            </Paragraph>
          </div>
        )}
      </div>
    </BasicLayout>
  );
}
